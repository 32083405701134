import { IEnterpriseNotification } from '@/features/profile/types/notifications.type';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import {
	getUserNotifications,
	readAllNotifications,
	readNotification,
} from '@/services/profile.service';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useNotifications = () => {
	const { user } = useAuth();
	const { data: notifications, isLoading: isNotificationsLoading } = useQuery<
		IEnterpriseNotification[]
	>({
		queryKey: ['notifications'],
		queryFn: () => getUserNotifications(),
		enabled: !!user,
	});

	const handleReadNotification = async (notificationId: string) => {
		try {
			const notification = notifications.find((i) => i._id === notificationId);
			analytics.track(TrackingEvents.NotificationClicked, {
				readAll: false,
				link: notification?.link,
			});
			await readNotification(notificationId);
			if (!notification) return;
			if (notification.link) {
				window.open(notification.link);
			}
			queryClient.invalidateQueries({
				queryKey: ['notifications'],
			});
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const handleReadAllNotifications = async () => {
		try {
			analytics.track(TrackingEvents.NotificationClicked, {
				readAll: true,
			});
			await readAllNotifications();
			queryClient.invalidateQueries({
				queryKey: ['notifications'],
			});
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const eventNotification = useMemo(() => {
		if (!notifications) return null;
		return notifications
			?.filter((i) => i.isRead)
			?.find((i) => i.notificationType === 'event');
	}, [notifications]);

	const bannerNotification = useMemo(() => {
		if (!notifications) return null;
		return notifications
			?.filter((i) => i.isRead)
			?.find((i) => i.notificationType === 'banner');
	}, [notifications]);

	const notifications_ = useMemo(() => {
		if (!notifications) return [];
		return notifications?.filter(
			(i) => i.notificationType !== 'event' && i.notificationType !== 'banner',
		);
	}, [notifications]);

	return {
		notifications: notifications_ || [],
		handleReadNotification,
		handleReadAllNotifications,
		bannerNotification,
		eventNotification,
		isNotificationsLoading,
		unreadNotifications: notifications?.filter((i) => !i.isRead)?.length || 0,
	};
};
