import { Media } from '@/components/element/media/Media';
import { Dispatch, SetStateAction } from 'react';
import { EnterpriseApprovalStatus } from '../../types/enterprise.types';

const WaitingForApproval = ({
	setOpen,
	approvalStatus,
	rejectionReason: enterpriseRejectionReason,
	rejectionComment,
}: {
	setOpen: Dispatch<SetStateAction<boolean>>;
	approvalStatus: EnterpriseApprovalStatus;
	rejectionReason?: string;
	rejectionComment?: string;
}) => {
	return (
		<div className="flex flex-col items-center justify-center space-y-5 px-6">
			<Media
				src={
					approvalStatus === EnterpriseApprovalStatus?.Rejected
						? 'https://static.highongrowth.xyz/enterprise/677cee2db4c9cfdac89fa346/0b28d015b0cc4abe9bb4d70af3898362.svg'
						: 'https://static.highongrowth.xyz/enterprise/66910984f04b49f32e42ad7b/d4d1b44e52d7477c8c9555bc55792cfe.svg'
				}
				className="h-[11.75rem] aspect-auto"
			/>
			<div className="flex items-center flex-col space-y-1">
				{approvalStatus !== EnterpriseApprovalStatus.Rejected ? (
					<h4 className="font-medium text-base leading-5">
						Waiting For Approval...
					</h4>
				) : (
					<p className="font-medium text-base leading-5 text-center">
						Your project approval request has unfortunately been
						rejected.
					</p>
				)}
				{approvalStatus !== EnterpriseApprovalStatus?.Rejected ? (
					<p className="font-normal text-muted-foreground text-sm text-center">
						This process may take some time to complete. You can close
						this tab and return later if you wish.
					</p>
				) : null}
			</div>
			{approvalStatus === EnterpriseApprovalStatus.Rejected ? (
				<div className="w-full space-y-3 bg-slate-50 rounded-lg p-4">
					<div>
						<p className="text-sm leading-normal text-slate-600 mb-1">
							Rejection Reason:
						</p>
						<p className="text-sm leading-5 text-slate-900 break-words">
							{rejectionReasons[enterpriseRejectionReason]}
						</p>
					</div>
					{rejectionComment && (
						<div>
							<p className="text-sm leading-normal text-slate-600 mb-1">
								Note from reviewer:
							</p>
							<p className="text-sm break-words max-h-[80px] overflow-y-scroll">
								{rejectionComment}
							</p>
						</div>
					)}
				</div>
			) : null}
			<div className="w-full bg-warning/5 py-3 px-4 rounded-md text-sm text-center">
				<p className="mb-1">
					{approvalStatus === EnterpriseApprovalStatus?.Rejected
						? 'Have any questions?'
						: 'Want to get approved faster?'}
				</p>
				<p
					className="text-warningForeground cursor-pointer hover:opacity-80 underline text-sm underline-offset-2"
					onClick={() => {
						window?.Intercom('show');
						setOpen(false);
					}}
				>
					Contact Us
				</p>
			</div>
		</div>
	);
};

export default WaitingForApproval;

const rejectionReasons = {
	scam: 'Suspected Scam or Fraudulent Activity',
	content: 'Inappropriate Content',
	individual: 'Invalid Request- Individuals ineligible to be Projects',
	duplicate: 'Project with same name already exists',
	incomplete: 'Project does not have all required details',
};
