import { defineChain } from 'viem';

export const eosEVM = defineChain({
	id: 17777,
	name: 'EOS EVM Mainnet',
	nativeCurrency: { name: 'EOS', symbol: 'EOS', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://api.evm.eosnetwork.com'],
		},
	},
	blockExplorers: {
		default: {
			name: 'EOS EVM Mainnet Explorer',
			url: 'https://explorer.evm.eosnetwork.com/',
		},
	},
	testnet: false,
});
