import { defineChain } from 'viem';

export const ink = defineChain({
	id: 57073,
	name: 'Ink',
	nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
	rpcUrls: {
		default: {
			http: ['https://rpc-gel.inkonchain.com/'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Ink Explorer',
			url: 'https://explorer.inkonchain.com/',
		},
	},
	testnet: false,
});
