import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import useTieredWinners from '@/features/campaigns/hooks/reward/useTieredWinners';
import { ICampaignAddReward } from '@/features/campaigns/types';
import { useEffect } from 'react';

const TieredWinners = ({
	reward,
	setReward,
}: {
	reward: ICampaignAddReward;
	setReward: (reward: ICampaignAddReward) => void;
}) => {
	const tiers = reward?.tiers;

	const { updateTierValues, handleTierUpdate, addTier, popTier } =
		useTieredWinners({
			reward,
			setReward,
		});

	useEffect(() => {
		updateTierValues();
	}, [reward?.numRewards]);

	const isAddTierDisabled = tiers?.length >= 5;

	const isEthereumChainSelected = reward?.tokenReward?.chain === 'ethereum';

	// console.log(tiers);

	return (
		<div className="p-4 bg-slate-50 rounded-lg text-slate-700">
			{tiers?.map((tier, index) => (
				<div className="flex gap-2 py-1 px-0.5" key={tier?._id}>
					<div className="flex flex-col flex-grow w-full">
						<p className="text-slate-700 font-medium leading-normal text-sm mb-2">
							{`${tier?.tierName} Winners`}
						</p>
						<div className="flex flex-col w-full">
							<div className="flex flex-grow items-center gap-2 w-full ">
								<div className="flex flex-col w-full">
									<div className="h-[70px]">
										<Input
											placeholder="Position on the leaderboard..."
											disabled={index === 0}
											value={tier?.from ?? ''}
											onChange={(e) =>
												handleTierUpdate(
													index,
													'from',
													e.target.value,
												)
											}
											onFocus={(e) => e.target.select()}
											className={
												tier?.errors?.from
													? 'border-red-500'
													: ''
											}
										/>
										<p className="text-xs font-normal leading-normal text-slate-400 pt-0.5">
											From
										</p>
										{tier?.errors?.from && (
											<p className="text-xs text-red-500 mt-1">
												{tier?.errors?.from}
											</p>
										)}
									</div>
								</div>
								<div className="flex flex-col w-full">
									<div className="h-[70px]">
										<Input
											placeholder="Position on the leaderboard..."
											disabled={index === tiers.length - 1}
											value={tier?.to ?? ''}
											onChange={(e) =>
												handleTierUpdate(
													index,
													'to',
													e.target.value,
												)
											}
											onFocus={(e) => e.target.select()}
											className={
												tier?.errors?.to
													? 'border-red-500'
													: ''
											}
										/>
										<p className="text-xs font-normal leading-normal text-slate-400 pt-0.5">
											To
										</p>
										{tier?.errors?.to && (
											<p className="text-xs text-red-500 mt-1">
												{tier?.errors?.to}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex flex-col flex-grow">
						<p className="text-slate-700 font-medium leading-normal text-sm mb-2">
							Reward per user
						</p>
						<div className="h-[70px]">
							<Input
								placeholder={
									isEthereumChainSelected
										? 'Min $20'
										: 'Enter reward'
								}
								value={tier?.rewardPerUser ?? ''}
								onChange={(e) =>
									handleTierUpdate(
										index,
										'rewardPerUser',
										e.target.value,
									)
								}
								onFocus={(e) => e.target.select()}
								className={
									tier?.errors?.rewardPerUser
										? 'border-red-500'
										: ''
								}
							/>
							{tier?.errors?.rewardPerUser && (
								<p className="text-xs text-red-500 mt-1">
									{tier?.errors?.rewardPerUser}
								</p>
							)}
						</div>
					</div>
				</div>
			))}
			<div className="flex gap-2 items-center mt-6 ms-0.5">
				<TooltipWrapper
					tooltip={
						isAddTierDisabled
							? 'You have already added maximum number of tiers'
							: ''
					}
					align="start"
				>
					<Button onClick={addTier} disabled={isAddTierDisabled}>
						+ Add Tier
					</Button>
				</TooltipWrapper>

				{tiers?.length > 2 ? (
					<Button onClick={popTier} variant="outline">
						<i className="bi-trash me-1"></i>Delete Tier
					</Button>
				) : null}
			</div>
		</div>
	);
};

export default TieredWinners;
