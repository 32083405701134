import { useState } from 'react';
import { IAdminEnterprises } from '../types/admin.types';
import {
	getAdminEnterprises,
	getAdminEnterprisesCount,
} from '../services/admin.service';
import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@/hooks/useDebounce';

export const useGetAdminEnterprises = () => {
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		approvalStatus: [],
		onboardingStatus: [],
		approvalStageCompleted: false,
		autoApproval: false,
	});
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});
	const [sorting, setSorting] = useState([]);
	const debounceSearch = useDebounce(search, 500);

	const { data, isFetching, refetch } = useQuery<IAdminEnterprises[]>({
		queryKey: [
			'admin',
			'enterprises',
			{
				pagination,
				filters,
				debounceSearch,
			},
		],
		queryFn: () =>
			getAdminEnterprises({
				page: Number(pagination.pageIndex + 1) ?? 1,
				limit: Number(pagination.pageSize) ?? 10,
				search: debounceSearch,
				approvalStatus: filters.approvalStatus,
				onboardingStatus: filters.onboardingStatus,
				approvalStageCompleted: filters.approvalStageCompleted,
				autoApproval: filters.autoApproval,
			}),
	});

	const { data: count } = useQuery<number>({
		queryKey: [
			'admin',
			'enterprises',
			{
				filters,
				debounceSearch,
			},
		],
		queryFn: () =>
			getAdminEnterprisesCount({
				search: debounceSearch,
				approvalStatus: filters.approvalStatus,
				onboardingStatus: filters.onboardingStatus,
				approvalStage: filters.approvalStageCompleted,
				autoApproval: filters.autoApproval,
			}),
	});

	return {
		search,
		setSearch,
		filters,
		setFilters,
		results: data || [],
		pagination,
		setPagination,
		sorting,
		setSorting,
		isLoading: isFetching,
		refetch,
		count,
	};
};
