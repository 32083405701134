import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Card, CardContent } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { CampaignRewardType, ICampaignAddReward } from '@/features/campaigns/types';
import { cn, getTotalRewardPool } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { useMemo } from 'react';
import RewardCardHeader from '../../others/RewardCardHeader';
import TieredWinners from '../TieredWinners';
import { SelectTokenAndChain } from './SelectTokenAndChain';
import { TokenRewardMethod } from './TokenRewardMethod';
import { TokenRewardWinners } from './TokenRewardWinners';

const AddERC20TokenReward = ({
	tokenReward,
	setTokenReward,
	errors,
	noEndTime,
	disableCustomTierLeaderboard,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: boolean | string;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
	noEndTime: boolean;
	disableCustomTierLeaderboard: boolean;
}) => {
	const totalRewardPool = getTotalRewardPool(tokenReward);

	const initialTiers = useMemo(() => {
		return [
			{
				_id: 1,
				tierName: 'Tier 1',
				from: 1,
				to: null,
				rewardPerUser: 1,
				errors: { from: '', to: '', rewardPerUser: '' },
			},
			{
				_id: 2,
				tierName: 'Tier 2',
				from: null,
				to: tokenReward?.numRewards || null,
				rewardPerUser: null,
				errors: { from: '', to: '', rewardPerUser: '' },
			},
		];
	}, [tokenReward?.numRewards]);

	return (
		<>
			<Card className="mb-10 mt-10 shadow-none">
				<RewardCardHeader
					title="ERC20 Tokens Rewards"
					description="ERC20 tokens are fungible tokens that are compatible with the Ethereum network."
					tooltip={
						'Quests with stable-coin rewards, with a pool of at least $1000 get the most completions. Higher is better.'
					}
					trackingEvent={TrackingEvents.ERC20ProTipViewed}
				/>
				<CardContent className="px-0 pb-3">
					<TokenRewardMethod
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors}
						noEndTime={noEndTime}
					/>
					<SelectTokenAndChain
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors as any}
					/>
					{tokenReward?.method === CampaignRewardType.Leaderboard ? (
						<>
							<div className="mb-5 px-6 flex items-center">
								<TooltipWrapper
									tooltip={
										disableCustomTierLeaderboard
											? 'You can only have one tiered leaderboard reward in your campaign.'
											: ''
									}
									align="start"
								>
									<Checkbox
										checked={
											tokenReward?.customTieredLeaderboard
										}
										onCheckedChange={(check) =>
											setTokenReward({
												...tokenReward,
												customTieredLeaderboard:
													check as boolean,
												tiers: check ? initialTiers : null,
												tokenReward: {
													...tokenReward?.tokenReward,
													tokenAmountPerUser: check
														? null
														: tokenReward?.tokenReward
																?.tokenAmountPerUser,
												},
											})
										}
										disabled={disableCustomTierLeaderboard}
										className="me-2"
									/>
								</TooltipWrapper>
								<div
									className={cn(
										'text-sm font-medium text-slate-700',
										disableCustomTierLeaderboard
											? 'text-muted-foreground line-through'
											: '',
									)}
								>
									Add tiers to your leaderboard’s reward
									distribution
									<TooltipWrapper
										tooltip={
											<span>
												Give a better share of the reward
												pool to your top reward winners.
												<p>
													Example: Top 10 winners get 30%
													of your reward pool, the next 50
													winners get 50%, and the last 40
													get the remaining.
												</p>
											</span>
										}
									>
										<i className="bi-info-circle ms-1 cursor-pointer"></i>
									</TooltipWrapper>
								</div>
							</div>
						</>
					) : null}
					<TokenRewardWinners
						tokenReward={tokenReward}
						setTokenReward={setTokenReward}
						errors={errors as any}
					/>
					{tokenReward?.customTieredLeaderboard ? (
						<div className="mb-5 px-6">
							<TieredWinners
								reward={tokenReward}
								setReward={setTokenReward}
							/>
						</div>
					) : null}
				</CardContent>
				{totalRewardPool > 0 ? (
					<CardContent className=" py-5 border-t">
						<div className="flex justify-between items-center">
							<div className="me-8">
								<div className="text-sm font-medium mb-1">
									<span className="text-muted-foreground">
										Total Reward Pool:
									</span>{' '}
									{totalRewardPool}
									{` `}${tokenReward?.tokenReward?.tokenSymbol}
								</div>
								<div className="text-xs text-muted-foreground">
									You can test the campaign before launching it and
									depositing the tokens
								</div>
							</div>
						</div>
					</CardContent>
				) : (
					<></>
				)}
			</Card>
		</>
	);
};

export default AddERC20TokenReward;
