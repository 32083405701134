import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { IDaveActions } from '../../../types/dave.types';
import { Badge } from '@/components/ui/badge';
import { getSentenceCase } from '@/utils/parsers';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { Checkbox } from '@/components/ui/checkbox';
import ChainBadge from '@/components/element/badges/ChainBadge';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const actionsColumns = (options: any): ColumnDef<IDaveActions>[] => {
	return [
		{
			id: 'select',
			header: ({ table }) => (
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						!!(table.getIsSomePageRowsSelected() && 'indeterminate')
					}
					onCheckedChange={(value) =>
						table.toggleAllPageRowsSelected(!!value)
					}
					aria-label="Select all"
				/>
			),
			cell: ({ row }) => (
				<Checkbox
					checked={row.getIsSelected()}
					onCheckedChange={(value) => row.toggleSelected(!!value)}
					aria-label="Select row"
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => (
				<div className="py-1 max-w-[18rem] truncate">
					{row.getValue('name')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'description',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Description" />
			),
			cell: ({ row }) => (
				<div className=" py-1 max-w-[15rem] truncate">
					{row.getValue('description')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'chainId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Chain" />
			),
			cell: ({ row }) => (
				<ChainBadge
					chainId={row.getValue('chainId')}
					className=" py-1 flex items-center"
					chain={row.original?.chain?.toString()}
					namespaceTag={row.original?.namespaceTag}
				/>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'category',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Category" />
			),
			cell: ({ row }) => (
				<Badge className="" variant="secondary">
					{getSentenceCase(row.getValue('category'))}
				</Badge>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'isApproved',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Is Approved" />
			),
			cell: ({ row }) => (
				<TooltipWrapper
					tooltip={
						row.getValue('isApproved')
							? ''
							: 'The action is pending approval from Intract team. Once this is approved, you can use this for creating queries and tasks. '
					}
				>
					<Badge className="" variant="secondary">
						{row.getValue('isApproved')
							? 'Approved'
							: 'Pending Approval'}
					</Badge>
				</TooltipWrapper>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
};
