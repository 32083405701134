import type { Option } from '@/types/';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';
import analytics from '@/lib/analytics';

export function FacetedFilter({
	title,
	options,
	selectedValues,
	setSelectedValues,
	className,
	varient,
	trackingObj,
	disabled,
}: {
	title: string;
	options: {
		value: string;
		label: string | JSX.Element;
		emoji?: string;
		img?: string;
		icon?: string;
	}[];
	selectedValues: string[];
	setSelectedValues: (values: string[]) => void;
	className?: string;
	varient?: 'default' | 'outline';
	trackingObj?: {
		event: string;
		params?: any;
	};
	disabled?: boolean;
}) {
	const handleTrackingClick = () => {
		if (trackingObj) {
			const enhancedTrackingObj = {
				...trackingObj,
				params: {
					...trackingObj.params,
				},
			};
			analytics.track(enhancedTrackingObj.event, enhancedTrackingObj.params);
		}
	};

	const handleValueChange = (value: string) => {
		const isSelected = selectedValues.includes(value);
		let newSelectedValues: string[];

		if (isSelected) {
			newSelectedValues = selectedValues.filter((v) => v !== value);
		} else {
			newSelectedValues = [...selectedValues, value];

			// Only trigger tracking when a new value is selected
			if (trackingObj) {
				const enhancedTrackingObj = {
					...trackingObj,
					params: {
						...trackingObj.params,
						selectedValues: newSelectedValues,
						newlySelectedValue: value,
					},
				};
				analytics.track(
					enhancedTrackingObj.event,
					enhancedTrackingObj.params,
				);
			}
		}

		setSelectedValues(newSelectedValues);
	};
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={varient ? varient : 'outline'}
					size="sm"
					className={`h-9 border-dashed ${className}`}
					onClick={() => handleTrackingClick()}
					disabled={disabled}
				>
					<PlusCircledIcon className="mr-2 size-4" />
					{title}
					{selectedValues?.length > 0 && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<Badge
								variant="secondary"
								className="rounded-sm px-1 font-normal lg:hidden"
							>
								{selectedValues.length}
							</Badge>
							<div className="hidden space-x-1 lg:flex">
								{selectedValues.length > 2 ? (
									<Badge
										variant="secondary"
										className="rounded-sm px-1 font-normal"
									>
										{selectedValues.length} selected
									</Badge>
								) : (
									options
										.filter((option) =>
											selectedValues?.includes?.(option.value),
										)
										.map((option) => (
											<Badge
												variant="secondary"
												key={option.value}
												className="rounded-sm px-1 font-normal"
											>
												{option.label}
											</Badge>
										))
								)}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[250px] p-0" align="start">
				<Command>
					<CommandInput placeholder={title} />
					<CommandList>
						<CommandEmpty>No results found.</CommandEmpty>
						<CommandGroup>
							{options.map((option) => {
								const isSelected = selectedValues?.includes(
									option.value,
								);
								return (
									<CommandItem
										key={option.value}
										onSelect={() => {
											handleValueChange(option.value);
										}}
									>
										<div
											className={cn(
												'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
												isSelected
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible',
											)}
										>
											<CheckIcon
												className={cn('size-4')}
												aria-hidden="true"
											/>
										</div>
										{option.emoji && (
											<span className="mr-2">
												{option.emoji}
											</span>
										)}
										{option?.icon ? (
											<i
												className={`bi-${option.icon} me-1.5 text-slate-600`}
											></i>
										) : null}
										<span className="flex gap-2 items-center">
											{option?.img ? (
												<img
													src={option.img}
													className="size-4 rounded-full"
												/>
											) : null}
											{option.label}
										</span>
									</CommandItem>
								);
							})}
						</CommandGroup>
						{selectedValues.length > 0 && (
							<>
								<CommandSeparator />
								<CommandGroup>
									<CommandItem
										onSelect={() => setSelectedValues([])}
										className="justify-center text-center"
									>
										Clear filters
									</CommandItem>
								</CommandGroup>
							</>
						)}
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
}
