import InputText from '@/components/element/inputs/InputText';
import { ICampaignAddReward } from '@/features/campaigns/types';

export const TokenRewardWinners = ({
	tokenReward,
	setTokenReward,
	errors,
}: {
	tokenReward: ICampaignAddReward;
	setTokenReward: (reward: ICampaignAddReward) => void;
	errors: {
		method: boolean;
		chain: boolean;
		token: boolean;
		winners: boolean;
		reward: boolean;
		expected: boolean;
	};
}) => {
	const isEthereumChainSelected = tokenReward?.tokenReward?.chain === 'ethereum';
	return (
		<div className="mt-5 mb-2 px-6">
			{tokenReward?.customTieredLeaderboard ? (
				<InputText
					label="Number of winners"
					placeholder="1000"
					value={tokenReward.numRewards ?? ''}
					setValue={(e) =>
						setTokenReward({
							...tokenReward,
							numRewards:
								e && !Number.isNaN(e) ? parseInt(e?.toString()) : 0,
						})
					}
					error={errors?.winners}
					errorText="Please enter a number of winners"
					type="number"
					onFocus={(e) => e.target.select()}
				/>
			) : (
				<div className="grid grid-cols-2 gap-2 ">
					<InputText
						label="Number of winners"
						placeholder="1000"
						value={
							tokenReward?.numRewards !== undefined
								? tokenReward.numRewards
								: ''
						}
						setValue={(e) =>
							setTokenReward({
								...tokenReward,
								numRewards:
									e && !Number.isNaN(e)
										? parseInt(e?.toString())
										: 0,
							})
						}
						error={errors?.winners}
						errorText="Please enter a number of winners"
						type="number"
						onFocus={(e) => e.target.select()}
					/>

					<InputText
						label="Reward per winner"
						placeholder={
							isEthereumChainSelected ? 'At least worth $20' : '10'
						}
						value={tokenReward?.tokenReward?.tokenAmountPerUser ?? ''}
						setValue={(e) =>
							setTokenReward({
								...tokenReward,
								tokenReward: {
									...tokenReward.tokenReward,
									tokenAmountPerUser: e?.trim(),
								},
							})
						}
						error={errors?.reward}
						errorText="Please enter a reward per winner"
						onFocus={(e) => e.target.select()}
					/>
				</div>
			)}
		</div>
	);
};
