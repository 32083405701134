import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';

export const createErrorMessage = {
	insufficientBalance: (
		userBalance: string,
		requiredAmount: string,
		tokenSymbol: string,
	) => {
		const userBalanceFormatted = Number(userBalance).toFixed(2);
		const requiredAmountFormatted = Number(requiredAmount).toFixed(2);
		return `Insufficient ${tokenSymbol} Balance. 
\nYou have: ${userBalanceFormatted} ${tokenSymbol}
\nRequired: ${requiredAmountFormatted} ${tokenSymbol}
\nShortfall: ${(Number(requiredAmount) - Number(userBalance)).toFixed(2)} ${tokenSymbol}`;
	},
	wrongNetwork: (currentChainId: number, requiredChainId: number) =>
		`Incorrect Network. 
		Current Network Chain ID: ${currentChainId}
		Required Network Chain ID: ${requiredChainId}
		Please switch to the correct network in your wallet.`,

	tokenApprovalFailed: (tokenSymbol: string) =>
		`Token Approval Failed for ${tokenSymbol}. 
\nPlease check:
\n- You have sufficient balance
\n- Your wallet is connected
\n- Network is correct`,

	transferFailed: (tokenSymbol: string, amount: string) =>
		`Transfer of ${amount} ${tokenSymbol} Failed. 
\nPossible reasons:
\n- Insufficient allowance
\n- Network congestion
\n- Smart contract issues`,

	speedrunTransferFailed: (tokenSymbol: string, amount: string) =>
		`Speedrun Pool Transfer of ${amount} ${tokenSymbol} Failed. 
\nPossible reasons:
\n- Insufficient balance
\n- Network connectivity issues
\n- Smart contract restrictions`,
};

interface ILoading {
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error?: {
		message: string;
		code?: string | number;
	};
	walletAddress?: string;
	txHash?: string;
	scanLink?: string;
	successMessage?: string;
}

export interface IDepositeTokenStatus {
	walletConnect: ILoading;
	requiredAmount: ILoading;
	approveToken: ILoading;
	transferToken: ILoading;
	transferSpeedRun: ILoading;
}

interface ILoading {
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
}
export interface IDepositeTokenStatus {
	walletConnect: ILoading;
	requiredAmount: ILoading;
	approveToken: ILoading;
	transferToken: ILoading;
	transferSpeedRun: ILoading;
}

export const useDepositTokenStatus = ({ isLoading }: { isLoading: boolean }) => {
	const { address } = useAccount();

	const [steps, setSteps] = useState<IDepositeTokenStatus>({
		walletConnect: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			walletAddress: undefined,
		},
		requiredAmount: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: undefined,
		},
		approveToken: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: undefined,
		},
		transferToken: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: undefined,
		},
		transferSpeedRun: {
			isLoading: false,
			isSuccess: false,
			isError: false,
			error: undefined,
		},
	});

	useEffect(() => {
		if (!isLoading) return;
		if (address) {
			setSteps((p) => ({
				...p,
				walletConnect: {
					isLoading: false,
					isSuccess: true,
					isError: false,
					walletAddress: address,
				},
			}));
		} else {
			updateSteps('walletConnect', true, false, false);
		}
	}, [address]);

	const resetSteps = () => {
		setSteps({
			walletConnect: {
				isLoading: address ? false : true,
				isSuccess: address ? true : false,
				isError: false,
				walletAddress: address,
			},
			requiredAmount: {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: undefined,
			},
			approveToken: {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: undefined,
			},
			transferToken: {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: undefined,
			},
			transferSpeedRun: {
				isLoading: false,
				isSuccess: false,
				isError: false,
				error: undefined,
			},
		});
	};

	//TODO: use named parameters instead 🤯
	const updateSteps = (
		step: string,
		isLoading: boolean,
		isSuccess: boolean,
		isError: boolean,
		data?: {
			error?: { message: string; code?: string | number };
			successMessage?: string;
			txHash?: string;
			scanLink?: string;
		},
	) => {
		setSteps((p) => ({
			...p,
			[step]: {
				isLoading,
				isSuccess,
				isError,
				...data,
			},
		}));
	};

	return {
		steps,
		setSteps,
		resetSteps,
		updateSteps,
	};
};
