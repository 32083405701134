import { Media } from '@/components/element/media/Media';
import { DropdownMenuSeparator } from '@/components/ui/dropdown-menu';
import { useNotifications } from '@/features/dashboard/hooks/useNotifications';
import { IEnterpriseNotification } from '@/features/profile/types/notifications.type';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import React from 'react';

const NotificationList = ({ size = 'lg' }: { size?: 'sm' | 'lg' }) => {
	const { notifications, handleReadNotification } = useNotifications();

	return (
		<>
			{notifications
				?.sort((a, b) => {
					return (
						new Date(b.createdAt).getTime() -
						new Date(a.createdAt).getTime()
					);
				})
				.map((i) => (
					<>
						<NotificationItem
							{...i}
							readNotification={handleReadNotification}
							size={size || 'sm'}
						/>
						<DropdownMenuSeparator />
					</>
				))}
		</>
	);
};

export default NotificationList;

interface NotificationItemProps extends IEnterpriseNotification {
	readNotification: (notificationId: string) => void;
	size: 'sm' | 'lg';
}

const NotificationItem = ({
	baseImage,
	_id,
	title,
	description,
	createdAt,
	isRead,
	readNotification,
	size,
}: NotificationItemProps) => {
	const colors = {
		red: {
			bg: 'bg-red-50',
			text: 'text-red-500',
		},
		green: {
			bg: 'bg-green-50',
			text: 'text-green-500',
		},
		blue: {
			bg: 'bg-blue-50',
			text: 'text-blue-500',
		},
		yellow: {
			bg: 'bg-yellow-50',
			text: 'text-yellow-500',
		},
		purple: {
			bg: 'bg-purple-50',
			text: 'text-purple-500',
		},
		black: {
			bg: 'bg-muted',
			text: 'text-slate-700',
		},
	};
	const { color, value, type } = baseImage || {
		color: 'red',
		value: 'bi-stars',
	};

	return (
		<div
			className="p-4 flex cursor-pointer w-full rounded-xl hover:bg-muted"
			onClick={() => {
				readNotification(_id);
			}}
		>
			{size === 'sm' ? (
				<></>
			) : (
				<div
					className={cn(
						`rounded-lg ${colors[color]?.bg} flex items-center justify-center `,
						{
							'min-w-10 h-10': size === 'lg',
						},
					)}
				>
					{type === 'image' ? (
						<Media
							src={value}
							altText="notification-image"
							className="size-10"
						/>
					) : (
						<i
							className={`${value} text-lg  ${colors[color]?.text}`}
						></i>
					)}
				</div>
			)}
			<div className="flex flex-col ms-3 w-full">
				<div className="text-sm font-normal ">{title}</div>
				{description && (
					<div className="text-xs text-muted-foreground mt-0.5">
						{description}
					</div>
				)}
				{size === 'lg' && (
					<div className="text-xs text-muted-foreground mt-2">
						{dayjs(createdAt).fromNow()}
					</div>
				)}
			</div>
			{!isRead && (
				<i className="bi-circle-fill text-[8px] text-violet-500 ms-1"></i>
			)}
		</div>
	);
};
