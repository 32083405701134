import FullScreenCreateLayout from '@/components/layout/full-screen-create/FullScreenCreateLayout';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { Status } from '@/features/campaigns/types';
import EnterpriseApprovalProcess from '@/features/dashboard/components/verify/EnterpriseApprovalProcess';
import { useGetEnterprise } from '@/features/dashboard/hooks/useGetEnterprise';
import { useRouter } from '@/hooks/useRouter';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from './CampaignCreateNavigation';
import Sections from './CampaignCreateSection';
import { links } from '@/config/links';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const CampaignCreateLayout = ({ children, section }: any) => {
	const { id } = useParams();
	const {
		details,
		isInitalising,
		showLaunch,
		setShowLaunch,
		rewards,
		setDetails,
		isUpdate,
		campaign,
	} = useCreateCampaign();
	const [showBestPractices, setShowBestPractices] = useState(false);
	const { query } = useRouter();

	const navigate = useNavigate();

	const { enterprise } = useGetEnterprise();

	const [showApprovalCompletionDialog, setShowApprovalCompletionDialog] =
		useState(false);

	const { isReordering, setIsReordering, tasks } = useCreateCampaign();
	const xp = useMemo(() => {
		return tasks?.reduce((acc, task) => {
			return acc + task.task?.xp;
		}, 0);
	}, [tasks]);

	if (isInitalising) {
		return <FullPageLoading />;
	}
	return (
		<FullScreenCreateLayout showGrid={section === 'tasks'}>
			<div className="relative">
				<FullScreenCreateLayout.Header>
					<FullScreenCreateLayout.Title
						name={details?.name}
						placeholder={'Untitled Quest'}
						status={Status.Draft}
						lastSaved={
							isUpdate && campaign?.updatedAt
								? new Date(campaign.updatedAt)
								: null
						}
						onFeedback={() => {
							window.open(links.feedbackForm, '_blank');
						}}
						setName={(e) => {
							setDetails((prev) => ({ ...prev, name: e }));
						}}
						onBack={() => navigate('/app/campaign/quests')}
						enterpriseCreditsBalance={
							enterprise?.credits?.balanceCredits
						}
					/>
					<FullScreenCreateLayout.Sections>
						<Sections />
					</FullScreenCreateLayout.Sections>
				</FullScreenCreateLayout.Header>
				<div className="absolute -bottom-14 end-10 z-10">
					{xp > 0 && <Badge className="me-2 h-8">{xp} XP</Badge>}
					<Button
						variant={isReordering ? 'default' : 'outline'}
						size="sm"
						onClick={() => setIsReordering((prev) => !prev)}
					>
						<i className="bi bi-sort-down me-1"></i>
						{!isReordering ? 'Reorder Tasks' : 'Save Ordering'}
					</Button>
				</div>
			</div>
			<FullScreenCreateLayout.Content>
				{children}
			</FullScreenCreateLayout.Content>
			<FullScreenCreateLayout.Navigation>
				<Navigation
					enterprise={enterprise}
					setShowApprovalCompletionDialog={setShowApprovalCompletionDialog}
					showBestPractices={showBestPractices}
					setShowBestPractices={setShowBestPractices}
				/>
			</FullScreenCreateLayout.Navigation>
			<EnterpriseApprovalProcess
				open={showApprovalCompletionDialog}
				setOpen={setShowApprovalCompletionDialog}
				enterpriseDetails={enterprise}
			/>
		</FullScreenCreateLayout>
	);
};

export default CampaignCreateLayout;
