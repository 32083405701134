import { useAuth } from '@/hooks/useAuth';
import { queryClient } from '@/lib/react-query';

import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { EnterpriseTokenStatus } from '@/features/dashboard/types/enterprise.types';
import { createEnterpriseV2 } from '@/services/profile.service';
import { handleErrorMessage } from '@/utils/notifications';
import { deepCopy, stripURLs, validateCompleteURL } from '@/utils/parsers';
import { useEffect, useState } from 'react';
import {
	checkTwitterHandle,
	fuzzyCheckProjectFromName,
} from '../services/onboarding.service';

export interface IOnboardingCommunity {
	name: string;
	twitter: string;
	tokenStatus?: string;
	tokenDetails?: {
		tokenAddress: string;
		tokenChainId: number | null;
		tokenChain: string;
	};
}

export interface IOnboardingSocials {
	twitter: string;
	website: string;
	discord: string;
}

type step = 'community' | 'socials' | 'completed';

const defaultErrors = {
	name: false,
	twitter: false,
};

//This hook is for a logged in user to create another enterprise
export const useCreateEnterprise = (setOpen: any) => {
	const { user, refetchUser } = useAuth();
	const [step, setStep] = useState<step>('community');
	const [isLoading, setIsLoading] = useState(false);

	const [community, setCommunity] = useState<IOnboardingCommunity>({
		name: '',
		twitter: '',
		tokenStatus: '',
		tokenDetails: {
			tokenAddress: '',
			tokenChainId: null,
			tokenChain: '',
		},
	});
	const [socials, setSocials] = useState<IOnboardingSocials>({
		twitter: '',
		website: '',
		discord: '',
	});
	const [errors, setErrors] = useState(defaultErrors);

	const [checkingSimilarCommunity, setCheckingSimilarCommunity] = useState(false);
	const [similarCommunity, setSimilarCommunity] = useState(null);
	const [twitterHandle, setTwitterHandle] = useState({
		verified: false,
		isLoading: false,
		associatedTo: '',
	});

	const validateURL = (url: string) => {
		const linkValidationRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/\S*)?$/;
		return linkValidationRegex.test(url);
	};

	const handleComplete = async () => {
		try {
			setIsLoading(true);

			const completeURL = validateCompleteURL(
				'https://www.twitter.com/' +
					community.twitter?.substring(
						community.twitter?.lastIndexOf('/') + 1,
					),
			);

			const strippedURL = stripURLs({
				twitter: completeURL,
			});

			const payload: IOnboardingCommunity = {
				name: community.name,
				twitter: strippedURL.twitter,
				tokenStatus: community.tokenStatus || EnterpriseTokenStatus.Unknown,
			};

			if (community.tokenStatus === EnterpriseTokenStatus.Listed) {
				payload.tokenDetails = community.tokenDetails;
			}

			await createEnterpriseV2({
				...payload,
				namespaceTag: IdentityNamespaceTag.EvmEvm,
			});

			setCommunity({
				...community,
				twitter: strippedURL.twitter,
			});

			setOpen(false);
			await refetchUser();
			queryClient.invalidateQueries();

			setIsLoading(false);
			setCommunity({
				name: '',
				twitter: '',
				tokenStatus: '',
				tokenDetails: {
					tokenAddress: '',
					tokenChainId: null,
					tokenChain: '',
				},
			});
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading(false);
			console.log(err);
		}
	};

	const handleNext = async () => {
		if (!validate(step)) return;

		await handleComplete();
	};

	useEffect(() => {
		setErrors(deepCopy(defaultErrors));
	}, [community, socials]);

	const validate = (step: step) => {
		const isValidated = true;
		if (!community.name || !community.twitter) {
			if (!community.name) setErrors((prev) => ({ ...prev, name: true }));
			if (!community.twitter)
				setErrors((prev) => ({ ...prev, twitter: true }));

			setIsLoading(false);
			return;
		}
		return isValidated;
	};

	const checkIfCommunityNameExists = async (name: string) => {
		try {
			setSimilarCommunity(null);
			setCheckingSimilarCommunity(true);
			const project = await fuzzyCheckProjectFromName(name);
			if (project) {
				setSimilarCommunity({
					link: project?.link,
					name: project?.name,
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			setCheckingSimilarCommunity(false);
		}
	};

	const checkIfTwitterHandleExists = async (twitterHandle: string) => {
		try {
			setTwitterHandle({
				verified: false,
				isLoading: true,
				associatedTo: '',
			});
			const handle = await checkTwitterHandle(twitterHandle);
			if (handle) {
				setTwitterHandle((prev) => ({
					...prev,
					verified: handle?.verified,
					associatedTo: handle?.associatedTo,
				}));
			}
		} catch (err) {
			console.log(err);
		} finally {
			setTwitterHandle((prev) => ({
				...prev,
				isLoading: false,
			}));
		}
	};

	return {
		step,
		setStep,
		handleNext,
		isLoading,
		errors,
		community,
		setCommunity,
		socials,
		setSocials,
		setErrors,
		checkIfCommunityNameExists,
		checkingSimilarCommunity,
		similarCommunity,
		setSimilarCommunity,
		checkIfTwitterHandleExists,
		twitterHandle,
		setTwitterHandle,
	};
};
